import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Fragment } from "react/cjs/react.production.min";

var img1 = require("./../../images/our-team5/Rabih-Barbar.jpg");

const members = [
  {
    image: require("./../../images/our-team5/3.jpg"),
    fullname: "Jamil Marwan Hmede",
    position: "Head of Interior Architects",
  },
  {
    image: require("./../../images/our-team5/1.jpg"),
    fullname: "Hiba Naji Bazzi ",
    position: "Interior Architect",
  },
  {
    image: require("./../../images/our-team5/2.jpg"),
    fullname: "Ibrahim Hussein Sadek",
    position: "Interior Architect",
  },
  {
    image: require("./../../images/our-team5/4.jpg"),
    fullname: "Hassan El Helani",
    position: "Architect",
  },
];

class TeamMembers extends React.Component {
  render() {
    return (
      <>
        <div className="section-full bg-gray square_shape3 tm-expert-wrap">
          <div className="container-fluid">
            <div className="section-content">
              <div className="row">
                <div className="col-md-6 col-sm-12 bg-black square_shape1 square_shape2">
                  <div className="wt-left-part m-experts p-t90">
                    {/* TITLE START */}
                    <div className="section-head text-left text-white">
                      <h2 className="text-uppercase font-36">Meet the team</h2>
                      <div className="wt-separator-outer">
                        <div className="wt-separator bg-white" />
                      </div>
                    </div>
                    {/* TITLE END */}
                    <div className="wt-team-six large-pic">
                    
                      <div className="wt-team-media wt-thum-bx">
                        <img src={img1.default} alt="" />
                      </div>
                      <div className="wt-team-info text-center p-lr10 p-tb20 text-white">
                        <h2 className="wt-team-title text-uppercase">
                          <span className="text-white font-32 font-weight-500">
                            Rabih Barbar
                          </span>
                        </h2>
                        <p className="font-22">Founder & Head Architect</p>
                        <p className="font-10 text-justify">
                          In 2012, Rabih Barbar founded R.B Architects 10 years
                          after graduating with an MA from INBA Lebanese
                          University.
                        </p>
                        <p className="font-10 text-justify">
                          Since the inception of the firm, Rabih maintained a
                          sustainable strategy and formed a team of individuals
                          with the same outlook. An aim to become one of the
                          main players in the region and a keen eye for quality
                          and innovation, helped shape R.B Architects into the
                          fast growing practice it is today. He believes that
                          ensuring a delicate balance between aesthetics and
                          functionality is always an intriguing challenge in the
                          design process. Rabih maintains direct relations and
                          continuous dialogues with his clients on each of the
                          firm’s projects, building a database of loyal,
                          long-term clientele.
                        </p>
                        <ul className="social-icons social-md social-square social-dark white-border has-bg">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/architectrabihbarbar/"
                              className="fa fa-facebook"
                            />
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/rabih-barbar-architecture-office/about/"
                              className="fa fa-linkedin"
                            />
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://instagram.com/architectrabihbarbar?igshid=MzRlODBiNWFlZA==/"
                              className="fa fa-instagram"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="hilite-title p-lr20 m-tb20 text-left text-uppercase bdr-gray bdr-left">
                      <strong className="text-gray">Experts</strong>
                      <span className="text-white">Team Members</span>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-right-part team-outer-1">
                  <div class="hilite-title p-lr20 m-tb20 text-left text-uppercase bdr-gray bdr-left"><strong class="text-gray">Experts</strong><span class="text-black">Team Members</span></div>
                    <div className="row">
                      {/* <div class="some-facts-1">
                        <div class="text-black">
                          <span class="font-40 font-weight-300  text-uppercase">
                            Some
                          </span>
                          <h2 class="font-50  text-uppercase">
                            Intresting Facts
                          </h2>
                          <p class="font-18 font-weight-300">
                            Morbi mattis ex non urna condimentum, eget eleif end
                            diam molestie. Curabitur lorem enim, maximus non
                            nulla sed, egestas venenatis felis.
                          </p>
                          <p class="font-18 font-weight-300">
                            Morbi mattis ex non urna condimentum, eget eleif end
                            diam molestie. Curabitur lorem enim, maximus non
                            nulla sed, egestas venenatis felis.
                          </p>
                          <p class="font-18 font-weight-300">
                            Morbi mattis ex non urna condimentum, eget eleif end
                            diam molestie. Curabitur lorem enim, maximus non
                            nulla sed, egestas venenatis felis.
                          </p>
                          <p class="font-18 font-weight-300">
                            Morbi mattis ex non urna condimentum, eget eleif end
                            diam molestie. Curabitur lorem enim, maximus non
                            nulla sed, egestas venenatis felis.
                          </p>
                          <p class="font-18 font-weight-300">
                            Morbi mattis ex non urna condimentum, eget eleif end
                            diam molestie. Curabitur lorem enim, maximus non
                            nulla sed, egestas venenatis felis.
                          </p>
                          <p class="font-18 font-weight-300">
                            Morbi mattis ex non urna condimentum, eget eleif end
                            diam molestie. Curabitur lorem enim, maximus non
                            nulla sed, egestas venenatis felis.
                          </p>
                        </div>
                      </div> */}
                      {members.map((item, index) => {
                        if (members.length % 2 !== 0) {
                          if (index + 1 === members.length) {
                            return (
                              <Fragment>
                                <div className="col-sm-3 col-xs-3"></div>
                                <div
                                  className="col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-tb15"
                                  key={index}
                                >
                                  <div className="wt-team-six bg-white">
                                    <div className="wt-team-media ">
                                      <img src={item.image.default} alt="" />
                                      {/* <div className="overlay-bx">
                                      <div className="overlay-icon">
                                        <ul className="social-icons social-square social-dark">
                                          <li>
                                            <a
                                              target="_blank"
                                              href="https://www.facebook.com/architectrabihbarbar/"
                                              className="fa fa-facebook"
                                            />
                                          </li>
                                          <li>
                                            <a
                                              target="_blank"
                                              href="https://www.linkedin.com/company/rabih-barbar-architecture-office/about/"
                                              className="fa fa-linkedin"
                                            />
                                          </li>
                                          <li>
                                            <a
                                              target="_blank"
                                              href="https://instagram.com/architectrabihbarbar?igshid=MzRlODBiNWFlZA=="
                                              className="fa fa-instagram"
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div> */}
                                    </div>
                                    <div className="wt-team-info text-center bg-white p-lr10 p-tb20">
                                      <h5 className="wt-team-title text-uppercase m-a0">
                                        <span>{item.fullname}</span>
                                      </h5>
                                      <p className="m-b0">{item.position}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-3 col-xs-3"></div>
                              </Fragment>
                            );
                          } else {
                            return (
                              <div
                                className="col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-tb15"
                                key={index}
                              >
                                <div className="wt-team-six bg-white">
                                  <div className="wt-team-media ">
                                    <img src={item.image.default} alt="" />
                                    {/* <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <ul className="social-icons social-square social-dark">
                                        <li>
                                          <a
                                            target="_blank"
                                            href="https://www.facebook.com/architectrabihbarbar/"
                                            className="fa fa-facebook"
                                          />
                                        </li>
                                        <li>
                                          <a
                                            target="_blank"
                                            href="https://www.linkedin.com/company/rabih-barbar-architecture-office/about/"
                                            className="fa fa-linkedin"
                                          />
                                        </li>
                                        <li>
                                          <a
                                            target="_blank"
                                            href="https://instagram.com/architectrabihbarbar?igshid=MzRlODBiNWFlZA=="
                                            className="fa fa-instagram"
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                  </div> */}
                                  </div>
                                  <div className="wt-team-info text-center bg-white p-lr10 p-tb20">
                                    <h5 className="wt-team-title text-uppercase m-a0">
                                      <span>{item.fullname}</span>
                                    </h5>
                                    <p className="m-b0">{item.position}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div
                              className="col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-tb15"
                              key={index}
                            >
                              <div className="wt-team-six bg-white">
                                <div className="wt-team-media">
                                  <img src={item.image.default} alt="" />
                                  {/* <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <ul className="social-icons social-square social-dark">
                                      <li>
                                        <a
                                          target="_blank"
                                          href="https://www.facebook.com/architectrabihbarbar/"
                                          className="fa fa-facebook"
                                        />
                                      </li>
                                      <li>
                                        <a
                                          target="_blank"
                                          href="https://www.linkedin.com/company/rabih-barbar-architecture-office/about/"
                                          className="fa fa-linkedin"
                                        />
                                      </li>
                                      <li>
                                        <a
                                          target="_blank"
                                          href="https://instagram.com/architectrabihbarbar?igshid=MzRlODBiNWFlZA=="
                                          className="fa fa-instagram"
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div> */}
                                </div>
                                <div className="wt-team-info text-center bg-white p-lr10 p-tb20">
                                  <h5 className="wt-team-title text-uppercase m-a0">
                                    <span>{item.fullname}</span>
                                  </h5>
                                  <p className="m-b0">{item.position}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TeamMembers;
