import React from "react";

const ScrolToTop = () => {
  return (
    <button
      className="scroltop"
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <span className="fa fa-angle-up  relative" id="btn-vibrate" />
    </button>
  );
};

export default ScrolToTop;
