import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withRouter } from "react-router-dom";

const filters = [
  { label: "Interior", filter: ".Interior-col" },
  { label: "Exterior", filter: ".Exterior-col" },
];

const projects = [
  {
    Id: 1,
    image: "latest/Front-Apartment-Mr-El-Sayid/1.jpg",
    title: "Appartement Mr. Ahmad Al Sayid",
    description:
      "Appartement Mr. Ahmad Al Sayid: A 250 sqm modern gem in Bekaa's Riyak, showcasing meticulous contemporary design.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 2,
    image: "latest/Front-Sheyaaka-Production-House/1.jpg",
    title: "SHEYAAKA Production Studio",
    description:
      "SHEYAAKA Studio: 300m² creative hub in Beirut's Verdun, merging photography, recording, and perfumery with top design.",
    filter: "Interior-col",
    year: 2023,
  },

  {
    Id: 3,
    image: "latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/4.jpg",
    title: "Restaurant and Wedding venue",
    description:
      "Restaurant & Wedding Venue: 2500 m², Deir Al-Qamar, Lebanon, complete with design and execution plans.",
    filter: "Interior-col",
    year: 2022,
  },

  {
    Id: 4,
    image: "latest/Front-commetcial-Complex/1.jpg",
    title: "Commercial Complex",
    description:
      "Commercial Complex: 7000 m² in Beirut, Airport Road, with modern design and execution, featuring retail, offices, and a restaurant.",
    filter: "Exterior-col",
    year: 2022,
  },

  {
    Id: 5,
    image: "latest/Front-Angola-Mall/1.jpg",
    title: "Mall in Angola",
    description:
      "Angola Mall: 30,000m² project in Angola, with storage, retail, parking, and detailed design and execution plans.",
    filter: "Exterior-col",
    year: 2017,
  },
  {
    Id: 6,
    image: "latest/Front-Fomatex/1.jpg",
    title: "Foma Home, Hadath",
    description:
      "Foma Home, Hadath: A 3000m² modern project in Lebanon with retail spaces expertly designed and executed.",
    filter: "Exterior-col",
    year: 2022,
  },
  {
    Id: 7,
    image: "Website-Photos-New/Exterior/Horizon-res-compound/1.jpg",
    title: "Horizon Residential Compound",
    description:
      "Horizon Residential Compound in Hariss, Lebanon: A 3000m² modern company complex, thoughtfully designed and executed.",
    filter: "Exterior-col",
    year: 2021,
  },
  {
    Id: 8,
    image: "latest/Front-PouleDor-Hadath/1.jpg",
    title: "Poule D’or Restaurant",
    description:
      "Poule D'or Restaurant, Hadath, Lebanon: A 1200 m² modern project with dining and snack areas, expertly executed.",
    filter: "Interior-col",
    year: 2018,
  },

  {
    Id: 9,
    image: "latest/Front-Poule-Dor-Koura/5.jpg",
    title: "Poule D’or Restaurant",
    description:
      "Poule D'or Restaurant, Al-Koura, Lebanon: A 2000 m² modern project with dining and snack areas, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 10,
    image: "latest/Front-Nabad-Gym-Jnah/1.jpg",
    title: "Nabad Gym",
    description:
      "Nabad Gym, Beirut, Lebanon: A 2000 m² modern interior design project, executed with contemporary precision.",
    filter: "Interior-col",
    year: 2016,
  },
  {
    Id: 11,
    image: "Website-Photos-New/Interior/Private-garage/1.jpg",
    title: "Private Car Display",
    description:
      "Private Car Display, Hariss, Lebanon: A 1000 m² modern project with boutique castle parking, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },

  {
    Id: 12,
    image: "latest/Front-Villa-Dbouk/10.jpg",
    title: "Villa Mr. Mohamad Dbouk",
    description:
      "Villa Mr. Mohamad Dbouk, Hadatha, Lebanon: A 2000 m² modern project harmonizing architecture, interior, and landscape design.",
    filter: "Interior-col",
    year: 2022,
  },
  {
    Id: 13,
    image: "latest/Front-Private-Diwania/1.jpg",
    title: "Private Diwaniya",
    description:
      "Private Diwaniya, Hariss, Lebanon: A 200 m² classic-style project within a castle, meticulously designed and executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 14,
    image: "Website-Photos-New/Interior/Ibrahim-Atat/1.jpg",
    title: "Mr.Ibrahim Al-Atat Appartement",
    description:
      "Mr. Ibrahim Al-Atat's Apartment, Jnah, Beirut, Lebanon: A 300 m² modern interior project, skillfully executed.",
    filter: "Interior-col",
    year: 2023,
  },
  {
    Id: 15,
    image: "latest/Front-Cigar-Lounge/1.jpg",
    title: "Private Luxury Cigar Lounge",
    description:
      "Private Cigar Lounge, Hariss, Lebanon: A 60 m² British neo-classic interior project within a castle, meticulously executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 16,
    image: "latest/Front-Private-Palace-in-Hariss/1.jpg",
    title: "Private Palace ",
    description:
      "Private Palace Remodeling, Hariss, Lebanon: An architectural and landscaping project rejuvenating an old palace.",
    filter: "Exterior-col",
    year: 2021,
  },
  {
    Id: 17,
    image: "latest/Front-Chello-Restaurant/3.jpg",
    title: "Chello Restauant",
    description:
      "Chello Restaurant, Saida, Lebanon: A 600 m² modern interior project with terraces, expertly executed.",
    filter: "Interior-col",
    year: 2016,
  },

  {
    Id: 18,
    image: "latest/Front-BBQ-house-in-Mozambique/1.jpg",
    title: "BBQ House (Franchise)",
    description:
      "BBQ House (Franchise), Mozambique: A 400 m² English modern-style pub and restaurant with detailed design and execution plans.",
    filter: "Interior-col",
    year: 2022,
  },
  {
    Id: 19,
    image: "Website-Photos-New/Exterior/Private-villa-joun/1.jpg",
    title: "Private Villa",
    description:
      "Private Villa in Joun, South Lebanon: A 3000 m² modern architecture and landscaping project featuring a triplex design with a pool.",
    filter: "Exterior-col",
    year: 2021,
  },

  {
    Id: 20,
    image: "Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/13.jpg",
    title: "Private Villa",
    description:
      "Private Villa, Kawthariyat Al-Sayed, South Lebanon: A 4000 m² modern project integrating interior, architecture, and landscaping with precision.",
    filter: "Interior-col",
    year: 2015,
  },
  {
    Id: 21,
    image: "latest/Front-Apartment-Mr-Mazloum/1.jpg",
    title: "Appartement Mr. Khodor Mazlum",
    description:
      "Appartement Mr. Khodor Mazlum, Jnah, Beirut, Lebanon: A 300 m² interior project with a mixed style, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 22,
    image: "latest/Front-Inizio-Sour/2.jpg",
    title: "Inizio Store",
    description:
      "Inizio Store, Tyre, South Lebanon: A 300m² modern design project for a fashion retail store, meticulously executed.",
    filter: "Interior-col",
    year: 2017,
  },
  {
    Id: 23,
    image: "Website-Photos-New/Interior/Damour-Spa/1.jpg",
    title: "Blue Sky Damour Interior",
    description:
      "Blue Sky Damour, Lebanon: A 6000m² modern project with chalets and a restaurant, expertly designed and executed.",
    filter: "Interior-col",
    year: 2023,
  },

  {
    Id: 24,
    image: "Website-Photos-New/Interior/Mr-Jawad-Arabsalim/3.jpg",
    title: "Villa Mr. Jawad",
    description:
      "Villa Mr. Jawad, Arab Salim, Lebanon: A 1600m² modern project with a triplex villa and pool, expertly designed and executed.",
    filter: "Interior-col",
    year: 2023,
  },
  {
    Id: 25,
    image: "latest/Front-Tabliyet-Massaad/1.jpg",
    title: "Tabliyet Massad (Franchise)",
    description:
      "Tabliyet Massad (Franchise), Tyre, Lebanon: An 800m² Lebanese-style project with dining and snack areas, expertly executed.",
    filter: "Interior-col",
    year: 2017,
  },
  {
    Id: 26,
    image: "latest/Front-Private-Villa-Hassan-in-Joun/8.jpg",
    title: "Villa Mr. Al Enjibar, Joun",
    description:
      "Villa Mr. Al Enjibar, Joun, Lebanon: A 1000m² modern project with a triplex villa and pool, expertly designed and executed.",
    filter: "Interior-col",
    year: 2023,
  },
  {
    Id: 27,
    image: "Website-Photos-New/Interior/Mkahal-bikaa/1.jpg",
    title: "Villa Mr. Mkahal",
    description:
      "Villa Mr. Mkahal, Riyak, Bekaa, Lebanon: A 10,000m² modern project with a triplex villa and pool, meticulously designed and executed.",
    filter: "Interior-col",
    year: 2023,
  },
  {
    Id: 28,
    image: "Website-Photos-New/Exterior/Zambia-offices/1.jpg",
    title: "Zambia Offices",
    description:
      "Zambia Offices: A modern architectural project in Zambia, Africa, focused on design and execution drawings for a foodstuffs company.",
    filter: "Exterior-col",
    year: 2023,
  },
  {
    Id: 29,
    image: "latest/Front-Maison-Maatouk/1.jpg",
    title: "Maison Maatouk",
    description:
      "Maison Maatouk, Beirut, Lebanon: A 160 m² modern interior project with a coffee shop, expertly executed.",
    filter: "Interior-col",
    year: 2018,
  },
  {
    Id: 30,
    image: "Website-Photos-New/Exterior/Foma/1.jpg",
    title: "Foma Home, Jnah",
    description:
      "Foma Home, Jnah, Beirut: A 3000 m² modern project with a furniture, display, and accessory showroom, expertly executed.",
    filter: "Interior-col",
    year: 2013,
  },
  {
    Id: 31,
    image: "latest/Front-Khodor-Mazloum-Villa/1.jpg",
    title: "Villa Mr. Khodor Mazlum",
    description:
      "Villa Mr. Khodor Mazlum, Anssar, Lebanon: An 11,000m² modern project with architecture, interior, and landscape, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 32,
    image: "latest/Front-Villa-Ahmad-Helbawi/13.jpg",
    title: "Villa Mr. Ahmad Helbawi",
    description:
      "Villa Mr. Ahmad Helbawi, Riyak, Lebanon: A 2500m² project with architecture, interior, and landscape design, expertly executed.",
    filter: "Interior-col",
    year: 2020,
  },
  {
    Id: 33,
    image: "Website-Photos-New/Exterior/Citycenter-iraq/1.jpg",
    title: "City Center Iraq",
    description:
      "City Center Iraq, Karbala: Designing the first mall with a 6000 m² architecture and exterior project, along with executional drawings.",
    filter: "Exterior-col",
    year: 2018,
  },
  {
    Id: 34,
    image: "latest/Front-Al-Rassoul-Al-Aazzam-Hospital/1.jpg",
    title: "Al Rassoul Al-Azam Hospital",
    description:
      "Al Rassoul Al-Azam Hospital: Exterior façade remodeling project.",
    filter: "Exterior-col",
    year: 2020,
  },
  {
    Id: 35,
    image: "latest/Front-9-Round-Gym/1.jpg",
    title: "9 Round Gym (Franchise)",
    description:
      "9 Round Gym (Franchise) in Jnah, Beirut, Lebanon: A 1000 m² modern interior design project, meticulously designed and executed.",
    filter: "Interior-col",
    year: 2020,
  },
  {
    Id: 36,
    image: "latest/Front-Zeus-Gym/1.jpg",
    title: "Zeus Gym",
    description:
      "Zeus Gym, Jnah, Beirut: A 400 m² interior design project for a personal trainers gym with an industrial modern style, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },

  {
    Id: 37,
    image: "Website-Photos-New/Exterior/Mr-baydoun/1.jpg",
    title: "Villa Mr. Baydoun",
    description:
      "Villa Mr. Baydoun, Bhamdoun, Lebanon: A Lebanese traditional-style project, thoughtfully designed and executed.",
    filter: "Exterior-col",
    year: 2017,
  },
  {
    Id: 38,
    image: "latest/Front-Commercial-Center-Kongo/1.jpg",
    title: "Centre Commercial Congo",
    description:
      "Centre Commercial Congo, Congo, Africa: A 5000 m² architecture project with design and execution drawings for a commercial center.",
    filter: "Exterior-col",
    year: 2018,
  },
  {
    Id: 39,
    image: "latest/Front-Mr-Houssam-Obadi-Apartment/1.jpg",
    title: "Appartement Mr. Houssam Obadi",
    description:
      "Appartement Mr. Houssam Obadi, Jnah, Beirut, Lebanon: A modern interior design project executed with precision and expertise.",
    filter: "Interior-col",
    year: 2020,
  },
  {
    Id: 40,
    image: "latest/Front-Villa-Ahmad-Achour-in-Syria/1.jpg",
    title: "Villa Syria Mr. Ahmad Ashour",
    description:
      "Villa Syria Mr. Ahmad Ashour, Jdaydet Yebouz, Syria: A 2000 m² duplex project with architecture and interior design, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 41,
    image: "latest/Front-Gandini-Menswear/1.jpg",
    title: "Gandini men’s wear",
    description:
      "Gandini Men's Wear, Hamra, Beirut, Lebanon: A 200 m² modern interior and exterior façade design project, executed with precision and expertise.",
    filter: "Exterior-col",
    year: 2017,
  },
  {
    Id: 42,
    image: "Website-Photos-New/Exterior/Nissane/1.jpg",
    title: "Nissan Cars Showroom",
    description:
      "Nissan Cars Showroom in Makka, Saudi Arabia: An exterior façade design project with a focus on design and executional drawings.",
    filter: "Exterior-col",
    year: 2014,
  },

  {
    Id: 43,
    image: "Website-Photos-New/Exterior/Ankoun/1.jpg",
    title: "Private Villa in Ankoun",
    description:
      "Private Villa, Ankoun, Lebanon: A 700 m² modern project with architecture and execution drawings, expertly executed.",
    filter: "Interior-col",
    year: 2021,
  },
  {
    Id: 44,
    image: "Website-Photos-New/New-to-add/Abu-Ali-Haris/1.jpg",
    title: "Villa Jawad, Haris",
    description:
      "",
      filter: "Interior-col",
    year: 2024,
  },
  {
    Id: 45,
    image: "Website-Photos-New/New-to-add/Chalet-Hamra/1.jpg",
    title: "Chalet Hamra",
    description:
      "",
      filter: "Interior-col",
    year: 2024,
  },
  {
    Id: 46,
    image: "Website-Photos-New/Interior/Damour-Spa/4.jpg",
    title: "Blue Sky Damour Exterior",
    description:
      "Blue Sky Damour, Lebanon: A 6000m² modern project with chalets and a restaurant, expertly designed and executed.",
    filter: "Exterior-col",
    year: 2023,
  },
  {
    Id: 47,
    image: "Website-Photos-New/Exterior/Bustan-al-sukkar/1.jpg",
    title: "Bustan Al Sukkar Exterior",
    description:
      "Situated in Ainab, Mount Lebanon, this modern architecture and interior landscape project offers elegantly designed commercial villas for rent, blending luxurious aesthetics with functional design from concept to execution.",
    filter: "Exterior-col",
    year: 2024,
  },
  {
    Id: 48,
    image: "Website-Photos-New/Interior/Bustan-al-sukkar/1.jpg",
    title: "Bustan Al Sukkar Interior",
    description:
      "Situated in Ainab, Mount Lebanon, this modern architecture and interior landscape project offers elegantly designed commercial villas for rent, blending luxurious aesthetics with functional design from concept to execution.",
    filter: "Interior-col",
    year: 2024,
  },
  {
    Id: 49,
    image:  "Website-Photos-New/Exterior/Hamra/1.jpg",
    title: "Lotus Villas",
    description:
      "Located in Al-Hamra, Nabatieh, these 2000 m² modern commercial villas blend luxury and functionality, offering premium rental spaces designed and executed to perfection.",
    filter: "Exterior-col",
    year: 2024,
  },
  {
    Id: 50,
    image:  "Website-Photos-New/Interior/Joun/1.jpg",
    title: "Villa Mr.Hassan Al Enjibar Interior",
    description:
      "Located in Joun, South Lebanon, this modern residential project seamlessly integrates architecture, interior, and landscape design for a cohesive and contemporary living experience.",
    filter: "Interior-col",
    year: 2024,
  },
  {
    Id: 51,
    image:  "Website-Photos-New/Exterior/Joun/1.jpg",
    title: "Villa Mr.Hassan Al Enjibar Exterior",
    description:
      "Located in Joun, South Lebanon, this 1000 m² modern residential project seamlessly integrates architecture, interior, and landscape design for a cohesive and contemporary living experience.",
    filter: "Exterior-col",
    year: 2024,
  },
];

class LatestProjects extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    const options = {
      loop: false,
      autoplay: true,
      autoplayTimeout: 9000,
      margin: 20,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 1,
        },
        580: {
          items: 2,
        },
        767: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1152: {
          items: 3,
        },
        1360: {
          items: 3,
        },
        1366: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div className="section-full p-t90 p-lr80 latest_project-outer square_shape3">
          {/* TITLE START */}
          <div className="section-head text-left">
            <div className="row">
              <div className="col-lg-4 col-md-12 ">
                <h2 className="text-uppercase font-36">Latest Projects</h2>
                <div className="wt-separator-outer">
                  <div className="wt-separator bg-black" />
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <ul className="btn-filter-wrap">
                  <li className="btn-filter btn-active" data-filter="*">
                    All Projects
                  </li>
                  {filters.map((item, index) => (
                    <li
                      key={index}
                      className="btn-filter"
                      data-filter={item.filter}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          {/* IMAGE CAROUSEL START */}
          <div className="section-content">
            <OwlCarousel
              className="owl-carousel owl-carousel-filter  owl-btn-bottom-left"
              {...options}
            >
              {projects.sort((a, b) => b.year - a.year).map((item, index) => {
                return (
                  <NavLink to={"/project-detail/" + item.Id}>
                    <div
                      key={index}
                      className={`${item.filter} item fadingcol cusror-pointer`}
                    >
                      <div className="wt-img-effect ">
                        <img
                          src={
                            require(`./../../images/projects/${item.image}`)
                              .default
                          }
                          loading="lazy"
                          alt=""
                        />
                        <div className="overlay-bx-2 ">
                          <div className="line-amiation">
                            <div className="text-white  font-weight-300 p-a30">
                              <h2>
                                {/* <NavLink
                                to={"/project-detail/" + item.Id}
                                className="text-white font-20 letter-spacing-1 text-uppercase"
                              > */}
                                {item.title}
                                {/* </NavLink> */}
                              </h2>
                              <p>{item.description}</p>
                              <span className="v-button letter-spacing-4 font-12 text-uppercase p-l20">
                                Read More
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </OwlCarousel>
          </div>
          <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
            <strong>Innovative</strong>
            <span className="text-black">Designs</span>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LatestProjects);
