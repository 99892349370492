import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';

import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';

import WorkGrid from './Pages/WorkGrid';
import ProjectDetail from './Pages/ProjectDetail';

const Components = () => {
    return (
        <BrowserRouter basename="/">
            <div className="page-wraper">
                <Switch>
                    <Route path='/' exact component={Home} />

                    <Route path='/aboutus' exact component={AboutUs} />
                    <Route path='/contactus' exact component={ContactUs} />

                    <Route path='/work-grid' exact component={WorkGrid} />
                    <Route path='/project-detail/:id' exact component={ProjectDetail} />

                    <Route component={Error} />
                </Switch>
            </div>
        </BrowserRouter>
    );
};

export default Components;
