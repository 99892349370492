import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// const images = [
//   require("./../../images/gallery/portrait/pic2.jpg"),
//   require("./../../images/gallery/portrait/pic3.jpg"),
//   require("./../../images/gallery/portrait/pic4.jpg"),
//   require("./../../images/gallery/portrait/pic5.jpg"),
//   require("./../../images/gallery/portrait/pic6.jpg"),
// ];

const images = [
  require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid.jpg"),
  require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar.jpg"),
  require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria.jpg"),
  require("./../../images/projects/latest/Front-Tabliyet-Massaad.jpg"),
  require("./../../images/projects/latest/Front-Private-Palace-in-Hariss.jpg"),
];

class About2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
    };
    return (
      <>
        <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
          <div className="container">
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="m-about m-l50 m-r50">
                    <OwlCarousel
                      className="owl-carousel about-us-carousel owl-btn-bottom-right"
                      {...options}
                    >
                      {images.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="ow-img wt-img-effect zoom-slow">
                            <NavLink to={""}>
                              <img src={item.default} alt="" />
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <div className="m-about-containt  text-black p-t30">
                    <span className="font-30 font-weight-300">About Us</span>
                    <h2 className="font-40">
                      RABIH BARBAR ARCHITECTURE OFFICE, BUILDING YOUR DREAMS.
                    </h2>
                    <p className="text-uppercase">
                      <b>
                        Rabih Barbar Architecture Office is an architectural and
                        interior design firm based in Beirut, dedicated to
                        serving a diverse clientele for over a decade.{" "}
                      </b>
                    </p>
                    <p >
                      Founded by Architect Rabih Barbar, our firm is
                      passionately committed to providing innovative solutions
                      that enhance our clients' quality of life through
                      architectural design.
                    </p>
                    {/* <NavLink
                      className="site-button black radius-no text-uppercase"
                      to={""}
                    >
                      <span className="font-12 letter-spacing-5">
                        {" "}
                        Read More{" "}
                      </span>
                    </NavLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About2;
