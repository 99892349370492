import React from 'react';

// const images = [
//     require('./../../images/gallery/portrait/pic1.jpg'),
//     require('./../../images/gallery/portrait/pic2.jpg'),
//     require('./../../images/gallery/portrait/pic3.jpg'),
//     require('./../../images/gallery/portrait/pic4.jpg'),
//     require('./../../images/gallery/portrait/pic5.jpg'),
//     require('./../../images/gallery/portrait/pic6.jpg')
// ]

const images = [
    require("./../../images/projects/latest/Front-Private-Villa-in-Ankoun.jpg"),
    require("./../../images/projects/latest/Front-Nabad-Gym-Jnah.jpg"),
    require("./../../images/projects/latest/Front-Angola-Mall.jpg"),
    require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique.jpg"),
    require("./../../images/projects/latest/Front-Inizio-Sour.jpg"),
    require("./../../images/projects/latest/Front-Gandini-Menswear.jpg"),
  ];

const servicesleft = [
    {
        counter: 0,
        title: 'Master Plans',
        description: 'Maintain an unwavering commitment to overseeing projects from start to finish.',
    },
    {
        counter: 1,
        title: '',
        description: '',
    },
    {
        counter: 2,
        title: '',
        description: '',
    },
    {
        counter: 3,
        title: 'Architecture',
        description: 'Offering comprehensive services in both interior and architectural fields.',
    }
]

const servicesright = [
    {
        counter: 3,
        title: 'Interior',
        description: 'Guaranteeing the highest quality and innovative functional design.',
    },
    {
        counter: 4,
        title: '',
        description: '',
    },
    {
        counter: 5,
        title: '',
        description: '',
    },
    {
        counter: 6,
        title: 'Construction',
        description: 'Initial analysis to shop and construction renovation, furniture design, and 3D visualization',
    }
]

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: window.innerWidth,
        };
    
        this.handleResize = this.handleResize.bind(this);
      }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
      }
    
      handleResize() {
        this.setState({
          windowWidth: window.innerWidth,
        });
      }
    render() {
console.log(this.state.windowWidth)
        return (
          <>
            <div className="section-full bg-black p-t90 m-t80 p-b30 square_shape1 tm-service2-wrap">
              <div className="container">
                {/* TITLE START */}
                <div className="section-head text-left text-white">
                  <h2 className="text-uppercase font-36">Our Services</h2>
                  <div className="wt-separator-outer">
                    <div className="wt-separator bg-white" />
                  </div>
                </div>
                {/* TITLE END */}
                <div
                  className="section-content circle-block-outer"
                  data-toggle="tab-hover"
                >
                  <div className="row">
                    <div className="col-md-4 col-sm-12 m-b30">
                        {
                            this.state.windowWidth > 1000 ? (
                                
                                    servicesleft.map((item, index) => {
                                          return (
                                            <div
                                              className={`${
                                                item.title !== "" && "wt-icon-box-wraper"
                                              } right p-a20 m-b50 text-white text-justify`}
                                              data-target={`#tab${item.counter}`}
                                              data-toggle="tab"
                                              key={index}
                                            >
                                              <div className="icon-content">
                                                <h4 className="wt-tilte text-uppercase">
                                                  {item.title}
                                                </h4>
                                                <p>{item.description}</p>
                                              </div>
                                            </div>
                                          );
                                        
                                      })
                                
                            ) : (
                                servicesleft.filter((i)=>i.title !== "").map((item, index) => {
                                    return (
                                      <div
                                        className={`${
                                          item.title !== "" && "wt-icon-box-wraper"
                                        } right p-a20 m-b50 text-white text-justify`}
                                        data-target={`#tab${item.counter}`}
                                        data-toggle="tab"
                                        key={index}
                                      >
                                        <div className="icon-content">
                                          <h4 className="wt-tilte text-uppercase">
                                            {item.title}
                                          </h4>
                                          <p>{item.description}</p>
                                        </div>
                                      </div>
                                    );
                                  
                                })
                            )
                        }
                     
                    </div>
                    <div className="col-md-4 col-sm-12 m-b30">
                      <div className="circle-content-pic tab-content ">
                        {images.map((item, index) => (
                          <div
                            className={
                              index == 0 ? `tab-pane active` : `tab-pane`
                            }
                            id={`tab${index}`}
                            key={index}
                          >
                            <div className="wt-box">
                              <div className="wt-media text-primary m-b20 ">
                                <img src={item.default} alt="" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 m-b30">
                    {
                            this.state.windowWidth > 1000 ? (
                                
                                servicesright.map((item, index) => {
                                          return (
                                            <div
                                              className={`${
                                                item.title !== "" && "wt-icon-box-wraper"
                                              } left p-a20 m-b50 text-white text-justify`}
                                              data-target={`#tab${item.counter}`}
                                              data-toggle="tab"
                                              key={index}
                                            >
                                              <div className="icon-content">
                                                <h4 className="wt-tilte text-uppercase">
                                                  {item.title}
                                                </h4>
                                                <p>{item.description}</p>
                                              </div>
                                            </div>
                                          );
                                        
                                      })
                                
                            ) : (
                                servicesright.filter((i)=>i.title !== "").map((item, index) => {
                                    return (
                                      <div
                                        className={`${
                                          item.title !== "" && "wt-icon-box-wraper"
                                        } let p-a20 m-b50 text-white text-justify`}
                                        data-target={`#tab${item.counter}`}
                                        data-toggle="tab"
                                        key={index}
                                      >
                                        <div className="icon-content">
                                          <h4 className="wt-tilte text-uppercase">
                                            {item.title}
                                          </h4>
                                          <p>{item.description}</p>
                                        </div>
                                      </div>
                                    );
                                  
                                })
                            )
                        }
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
};

export default Services;