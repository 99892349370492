import React, { useEffect } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Slider from './../Segments/Slider';
import About from './../Segments/About';
import LatestProjects from './../Segments/LatestProjects';
import TeamMembers from './../Segments/TeamMembers';
import Statistics from './../Segments/Statistics';
import ClientsLogo from './../Segments/ClientsLogo';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="page-content">
                <Slider />
                <About />
                <LatestProjects />
                <Statistics />
                <ClientsLogo />
                <TeamMembers />
            </div>
            <Footer />
        </>
    );
};

export default Home;
