import React from "react";
import Header2 from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "./../Segments/Banner";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var bnrimg = require("./../../images/main-slider/slider1/Banner-1.jpg");
var bgimg = require("./../../images/banner/5.jpg");

const projects = [
  {
    Id: 1,
    image: [
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/1.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/2.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/3.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/4.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/5.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/6.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/7.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/8.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-El-Sayid/9.jpg"),
    ],
    title: "Appartement Mr. Ahmad Al Sayid",
    description:
      "Appartement Mr. Ahmad Al Sayid is an outstanding interior architecture project located in the charming region of Lebanon, specifically in Bekaa, Riyak. With a modern design style, this 250 square meter space is a testament to meticulous design and execution. It's a sophisticated and contemporary living space that reflects the owner's taste for modern aesthetics in the heart of Bekaa.",
    filter: "Design and execution",
    type: "Interior and Architecture project",
    year: 2021,
  },
  {
    Id: 2,
    image: [
      require("./../../images/projects/latest/Front-Sheyaaka-Production-House/1.jpg"),
      require("./../../images/projects/latest/Front-Sheyaaka-Production-House/2.jpg"),
      require("./../../images/projects/latest/Front-Sheyaaka-Production-House/3.jpg"),
      require("./../../images/projects/latest/Front-Sheyaaka-Production-House/4.jpg"),
      require("./../../images/projects/latest/Front-Sheyaaka-Production-House/5.jpg"),
      require("./../../images/projects/latest/Front-Sheyaaka-Production-House/6.jpg"),
    ],
    title: "SHEYAAKA Production Studio",
    description:
      "SHEYAAKA Studio: 300m² creative hub in Beirut's Verdun, merging photography, recording, and perfumery with top design.",
    filter: "Design and Execution",
    type: "Interior design project",
    year: 2023,
  },

  {
    Id: 3,
    image: [
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/1.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/2.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/3.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/4.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/5.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/6.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/7.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/8.jpg"),
      require("./../../images/projects/latest/Front-Restaurant-Wedding-Venue-in-Deir-Al-Qamar/9.jpg"),
    ],
    title: "Restaurant and Wedding venue",
    description:
      "Restaurant & Wedding Venue: 2500 m², Deir Al-Qamar, Lebanon, complete with design and execution plans.",
    filter: "Design and Interior Execution Drawings",
    type: "Landscaping project",
    year: 2022,
  },

  {
    Id: 4,
    image: [
      require("./../../images/projects/latest/Front-commetcial-Complex/1.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/2.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/3.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/4.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/5.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/6.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/7.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/8.jpg"),
      require("./../../images/projects/latest/Front-commetcial-Complex/9.jpg"),
    ],
    title: "Commercial Complex",
    description:
      "Commercial Complex: 7000 m² in Beirut, Airport Road, with modern design and execution, featuring retail, offices, and a restaurant.",
    filter: "Design and Execution",
    type: "Architecture project",
    year: 2022,
  },
  {
    Id: 5,
    image: [
      require("./../../images/projects/latest/Front-Angola-Mall/1.jpg"),
      require("./../../images/projects/latest/Front-Angola-Mall/2.jpg"),
    ],
    title: "Mall in Angola",
    description:
      "Angola Mall: 30,000m² project in Angola, with storage, retail, parking, and detailed design and execution plans.",
    filter: "Design and executional drawing",
    type: "Architecture and Landscape project",
    year: 2017,
  },
  {
    Id: 6,
    image: [
      require("./../../images/projects/latest/Front-Fomatex/1.jpg"),
      require("./../../images/projects/latest/Front-Fomatex/3.jpg"),
    ],
    title: "Foma Home, Hadath",
    description:
      "Foma Home, Hadath: A 3000m² modern project in Lebanon with retail spaces expertly designed and executed.",
    filter: "Design and Execution",
    type: "Exterior and Architecture project",
    year: 2022,
  },
  {
    Id: 7,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Horizon-res-compound/8.jpg"),
    ],
    title: "Horizon Residential Compound",
    description:
      "Horizon Residential Compound in Hariss, Lebanon: A 3000m² modern company complex, thoughtfully designed and executed.",
    filter: "Design and Execution",
    type: "Architecture project",
    year: 2021,
  },
  {
    Id: 8,
    image: [
      require("./../../images/projects/latest/Front-PouleDor-Hadath/1.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/2.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/3.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/4.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/5.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/6.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/7.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/8.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/9.jpg"),
      require("./../../images/projects/latest/Front-PouleDor-Hadath/10.jpg"),
    ],
    title: "Poule D’or Restaurant",
    description:
      "Poule D'or Restaurant, Hadath, Lebanon: A 1200 m² modern project with dining and snack areas, expertly executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2018,
  },

  {
    Id: 9,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Poule-dor/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Poule-dor/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Poule-dor/3.jpg"),
    ],
    title: "Poule D’or Restaurant",
    description:
      "Poule D'or Restaurant, Al-Koura, Lebanon: A 2000 m² modern project with dining and snack areas, expertly executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2021,
  },
  {
    Id: 10,
    image: [
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/1.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/2.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/3.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/4.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/5.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/6.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/7.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/8.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/9.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/10.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/11.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/12.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/13.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/14.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/15.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/16.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/17.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/18.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/19.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/20.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/21.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/22.jpg"),
      require("./../../images/projects/latest/Front-Nabad-Gym-Jnah/23.jpg"),
    ],
    title: "Nabad Gym",
    description:
      "Nabad Gym, Beirut, Lebanon: A 2000 m² modern interior design project, executed with contemporary precision.",
    filter: "Design and Execution",
    type: "Interior design project",
    year: 2016,
  },
  {
    Id: 11,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Private-garage/7.jpg"),
    ],
    title: "Private Car Display",
    description:
      "Private Car Display, Hariss, Lebanon: A 1000 m² modern project with boutique castle parking, expertly executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2021,
  },

  {
    Id: 12,
    image: [
      require("./../../images/projects/latest/Front-Villa-Dbouk/1.jpg"),

      require("./../../images/projects/latest/Front-Villa-Dbouk/2.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/3.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/4.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/5.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/6.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/7.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/8.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/9.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/10.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/11.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/12.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/13.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/14.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/15.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/16.jpg"),
      require("./../../images/projects/latest/Front-Villa-Dbouk/17.jpg"),
    ],
    title: "Villa Mr. Mohamad Dbouk",
    description:
      "Villa Mr. Mohamad Dbouk, Hadatha, Lebanon: A 2000 m² modern project harmonizing architecture, interior, and landscape design.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2022,
  },
  {
    Id: 13,
    image: [
      require("./../../images/projects/latest/Front-Private-Diwania/1.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/2.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/3.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/4.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/5.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/6.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/7.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/8.jpg"),
      require("./../../images/projects/latest/Front-Private-Diwania/9.jpg"),
    ],
    title: "Private Diwaniya",
    description:
      "Private Diwaniya, Hariss, Lebanon: A 200 m² classic-style project within a castle, meticulously designed and executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2021,
  },
  {
    Id: 14,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/8.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/9.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/10.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Ibrahim-Atat/11.jpg"),
      // require("./../../images/projects/latest/Front-Apartment-Ibrahim-Al-Atat/12.jpg"),
      // require("./../../images/projects/latest/Front-Apartment-Ibrahim-Al-Atat/13.jpg"),
    ],
    title: "Mr.Ibrahim Al-Atat Appartement",
    description:
      "Mr. Ibrahim Al-Atat's Apartment, Jnah, Beirut, Lebanon: A 300 m² modern interior project, skillfully executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2023,
  },
  {
    Id: 15,
    image: [
      require("./../../images/projects/latest/Front-Cigar-Lounge/1.jpg"),
      require("./../../images/projects/latest/Front-Cigar-Lounge/2.jpg"),
      require("./../../images/projects/latest/Front-Cigar-Lounge/3.jpg"),
      require("./../../images/projects/latest/Front-Cigar-Lounge/4.jpg"),
      require("./../../images/projects/latest/Front-Cigar-Lounge/5.jpg"),
      require("./../../images/projects/latest/Front-Cigar-Lounge/6.jpg"),
    ],
    title: "Private Luxury Cigar Lounge",
    description:
      "Private Cigar Lounge, Hariss, Lebanon: A 60 m² British neo-classic interior project within a castle, meticulously executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2021,
  },
  {
    Id: 16,
    image: [
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/1.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/2.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/3.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/4.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/5.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/6.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/7.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/8.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/9.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/10.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/11.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/12.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/13.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/14.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/15.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/16.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/17.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/18.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/19.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/20.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/21.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/22.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/23.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/24.jpg"),
      require("./../../images/projects/latest/Front-Private-Palace-in-Hariss/25.jpg"),
    ],
    title: "Private Palace",
    description:
      "Private Palace Remodeling, Hariss, Lebanon: An architectural and landscaping project rejuvenating an old palace.",
    filter: "",
    type: "Architecture and Landscaping project",
    year: 2021,
  },
  {
    Id: 17,
    image: [
      require("./../../images/projects/latest/Front-Chello-Restaurant/1.jpg"),
      require("./../../images/projects/latest/Front-Chello-Restaurant/2.jpg"),
      require("./../../images/projects/latest/Front-Chello-Restaurant/3.jpg"),
      require("./../../images/projects/latest/Front-Chello-Restaurant/4.jpg"),
      require("./../../images/projects/latest/Front-Chello-Restaurant/5.jpg"),
      require("./../../images/projects/latest/Front-Chello-Restaurant/6.jpg"),
      require("./../../images/projects/latest/Front-Chello-Restaurant/7.jpg"),
    ],
    title: "Chello Restauant",
    description:
      "Chello Restaurant, Saida, Lebanon: A 600 m² modern interior project with terraces, expertly executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2016,
  },

  {
    Id: 18,
    image: [
      require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique/1.jpg"),
      require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique/2.jpg"),
      require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique/3.jpg"),
      require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique/4.jpg"),
      require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique/5.jpg"),
      require("./../../images/projects/latest/Front-BBQ-house-in-Mozambique/6.jpg"),
    ],
    title: "BBQ House (Franchise)",
    description:
      "BBQ House (Franchise), Mozambique: A 400 m² English modern-style pub and restaurant with detailed design and execution plans.",
    filter: "Design and Interior Execution Drawings",
    type: "",
    year: 2022,
  },
  {
    Id: 19,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Private-villa-joun/7.jpg"),
    ],
    title: "Private Villa",
    description:
      "Private Villa in Joun, South Lebanon: A 3000 m² modern architecture and landscaping project featuring a triplex design with a pool.",
    filter: "Design",
    type: "Architecture and Landscaping project",
    year: 2021,
  },

  {
    Id: 20,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/8.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/9.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/10.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/11.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/12.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/13.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/14.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/15.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/16.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/17.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/18.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/19.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/20.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/21.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/22.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/23.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Kawthariyat-el-Siyad-Villa/24.jpg"),
    ],
    title: "Private Villa",
    description:
      "Private Villa, Kawthariyat Al-Sayed, South Lebanon: A 4000 m² modern project integrating interior, architecture, and landscaping with precision.",
    filter: "Design and Execution",
    type: "Interior and Architecture and Landscaping project",
    year: 2015,
  },
  {
    Id: 21,
    image: [
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/1.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/2.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/3.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/4.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/5.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/6.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/7.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/8.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/9.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/10.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/11.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/12.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/13.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/14.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/15.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/16.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/17.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/18.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/19.jpg"),
      require("./../../images/projects/latest/Front-Apartment-Mr-Mazloum/20.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mazloum-Jnah/1.jpg"),
    ],
    title: "Appartement Mr. Khodor Mazlum",
    description:
      "Appartement Mr. Khodor Mazlum, Jnah, Beirut, Lebanon: A 300 m² interior project with a mixed style, expertly executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2021,
  },
  {
    Id: 22,
    image: [
      require("./../../images/projects/latest/Front-Inizio-Sour/1.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/2.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/3.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/4.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/5.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/6.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/7.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/8.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/9.jpg"),
      require("./../../images/projects/latest/Front-Inizio-Sour/10.jpg"),
    ],
    title: "Inizio Store",
    description:
      "Inizio Store, Tyre, South Lebanon: A 300m² modern design project for a fashion retail store, meticulously executed.",
    filter: "Design and Execution",
    type: "Exterior Façade and Interior design project",
    year: 2017,
  },
  {
    Id: 23,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/8.jpg"),
    ],
    title: "Blue Sky Damour Interior",
    description:
      "Blue Sky Damour, Lebanon: A 6000m² modern project with chalets and a restaurant, expertly designed and executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2023,
  },

  {
    Id: 24,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/8.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/9.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/10.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mr-Jawad-Arabsalim/11.jpg"),
    ],
    title: "Villa Mr. Jawad",
    description:
      "Villa Mr. Jawad, Arab Salim, Lebanon: A 1600m² modern project with a triplex villa and pool, expertly designed and executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2023,
  },
  {
    Id: 25,
    image: [
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/1.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/2.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/3.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/4.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/5.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/6.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/7.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/8.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/9.jpg"),
      require("./../../images/projects/latest/Front-Tabliyet-Massaad/10.jpg"),
    ],
    title: "Tabliyet Massad (Franchise)",
    description:
      "Tabliyet Massad (Franchise), Tyre, Lebanon: An 800m² Lebanese-style project with dining and snack areas, expertly executed.",
    filter: "Design and Execution",
    type: "Exterior Façade and Interior design project",
    year: 2017,
  },
  {
    Id: 26,
    image: [
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/1.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/2.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/3.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/4.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/5.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/6.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/7.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/8.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/9.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/10.jpg"),
      require("./../../images/projects/latest/Front-Private-Villa-Hassan-in-Joun/11.jpg"),
    ],
    title: "Villa Mr. Al Enjibar, Joun",
    description:
      "Villa Mr. Al Enjibar, Joun, Lebanon: A 1000m² modern project with a triplex villa and pool, expertly designed and executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2023,
  },
  {
    Id: 27,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Mkahal-bikaa/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mkahal-bikaa/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Mkahal-bikaa/3.jpg"),
    ],
    title: "Villa Mr. Mkahal",
    description:
      "Villa Mr. Mkahal, Riyak, Bekaa, Lebanon: A 10,000m² modern project with a triplex villa and pool, meticulously designed and executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2023,
  },
  {
    Id: 28,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Zambia-offices/7.jpg"),
    ],
    title: "Zambia Offices",
    description:
      "Zambia Offices: A modern architectural project in Zambia, Africa, focused on design and execution drawings for a foodstuffs company.",
    filter: "Design and Architecture Execution Drawings",
    type: "Architecture project",
    year: 2023,
  },
  {
    Id: 29,
    image: [
      require("./../../images/projects/latest/Front-Maison-Maatouk/1.jpg"),
      require("./../../images/projects/latest/Front-Maison-Maatouk/2.jpg"),
      require("./../../images/projects/latest/Front-Maison-Maatouk/3.jpg"),
      require("./../../images/projects/latest/Front-Maison-Maatouk/4.jpg"),
      require("./../../images/projects/latest/Front-Maison-Maatouk/5.jpg"),
      require("./../../images/projects/latest/Front-Maison-Maatouk/6.jpg"),
    ],
    title: "Maison Maatouk",
    description:
      "Maison Maatouk, Beirut, Lebanon: A 160 m² modern interior project with a coffee shop, expertly executed.",
    filter: "Design and Execution",
    type: "Interior design project",
    year: 2018,
  },
  {
    Id: 30,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Foma/8.jpg"),
    ],
    title: "Foma Home, Jnah",
    description:
      "Foma Home, Jnah, Beirut: A 3000 m² modern project with a furniture, display, and accessory showroom, expertly executed.",
    filter: "Design and Execution",
    type: "Interior and Architecture project",
    year: 2013,
  },

  {
    Id: 31,
    image: [
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/1.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/2.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/3.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/4.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/5.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/6.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/7.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/8.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/9.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/10.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/11.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/12.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/13.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/14.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/15.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/16.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/17.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/18.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/19.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/20.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/21.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/22.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/23.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/24.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/25.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/26.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/27.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/28.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/29.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/30.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/31.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/32.jpg"),
      require("./../../images/projects/latest/Front-Khodor-Mazloum-Villa/33.jpg"),
    ],
    title: "Villa Mr. Khodor Mazlum",
    description:
      "Villa Mr. Khodor Mazlum, Anssar, Lebanon: An 11,000m² modern project with architecture, interior, and landscape, expertly executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2021,
  },
  {
    Id: 32,
    image: [
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/1.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/2.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/3.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/4.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/5.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/6.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/7.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/8.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/9.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/10.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/11.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/12.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Helbawi/13.jpg"),
    ],
    title: "Villa Mr. Ahmad Helbawi",
    description:
      "Villa Mr. Ahmad Helbawi, Riyak, Lebanon: A 2500m² project with architecture, interior, and landscape design, expertly executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2020,
  },
  {
    Id: 33,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Citycenter-iraq/1.jpg"),
      require("./../../images/projects/City-Center-Iraq/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Citycenter-iraq/2.jpg"),
    ],
    title: "City Center Iraq",
    description:
      "City Center Iraq, Karbala: Designing the first mall with a 6000 m² architecture and exterior project, along with executional drawings.",
    filter: "Design and executional drawing",
    type: "Architecture and Exterior project",
    year: 2018,
  },
  {
    Id: 34,
    image: [
      require("./../../images/projects/latest/Front-Al-Rassoul-Al-Aazzam-Hospital/1.jpg"),
    ],
    title: "Al Rassoul Al-Azam Hospital",
    description:
      "Al Rassoul Al-Azam Hospital: Exterior façade remodeling project.",
    filter: "",
    type: "Exterior façade remodeling",
    year: 2020,
  },
  {
    Id: 35,
    image: [
      require("./../../images/projects/latest/Front-9-Round-Gym/1.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/2.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/3.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/4.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/5.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/6.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/7.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/8.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/9.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/10.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/11.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/12.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/13.jpg"),
      require("./../../images/projects/latest/Front-9-Round-Gym/14.jpg"),
    ],
    title: "9 Round Gym (Franchise)",
    description:
      "9 Round Gym (Franchise) in Jnah, Beirut, Lebanon: A 1000 m² modern interior design project, meticulously designed and executed.",
    filter: "Design and Execution",
    type: "Interior design project",
    year: 2020,
  },
  {
    Id: 36,
    image: [
      require("./../../images/projects/latest/Front-Zeus-Gym/1.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/2.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/3.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/4.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/5.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/6.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/7.jpg"),
      require("./../../images/projects/latest/Front-Zeus-Gym/8.jpg"),
    ],
    title: "Zeus Gym",
    description:
      "Zeus Gym, Jnah, Beirut: A 400 m² interior design project for a personal trainers gym with an industrial modern style, expertly executed.",
    filter: "Design and Execution",
    type: "Interior design project",
    year: 2021,
  },

  {
    Id: 37,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Mr-baydoun/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Mr-baydoun/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Mr-baydoun/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Mr-baydoun/4.jpg"),
    ],
    title: "Villa Mr. Baydoun",
    description:
      "Villa Mr. Baydoun, Bhamdoun, Lebanon: A Lebanese traditional-style project, thoughtfully designed and executed.",
    filter: "Design and Execution",
    type: "Architecture and Landscape project",
    year: 2017,
  },
  {
    Id: 38,
    image: [
      require("./../../images/projects/latest/Front-Commercial-Center-Kongo/1.jpg"),
      require("./../../images/projects/latest/Front-Commercial-Center-Kongo/2.jpg"),
      require("./../../images/projects/latest/Front-Commercial-Center-Kongo/3.jpg"),
    ],
    title: "Centre Commercial Congo",
    description:
      "Centre Commercial Congo, Congo, Africa: A 5000 m² architecture project with design and execution drawings for a commercial center.",
    filter: "Design and execution drawing",
    type: "Architecture project",
    year: 2018,
  },
  {
    Id: 39,
    image: [
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/1.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/2.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/3.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/4.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/5.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/6.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/7.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/8.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/9.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/10.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/11.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/12.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/13.jpg"),
      require("./../../images/projects/latest/Front-Mr-Houssam-Obadi-Apartment/14.jpg"),
    ],
    title: "Appartement Mr. Houssam Obadi",
    description:
      "Appartement Mr. Houssam Obadi, Jnah, Beirut, Lebanon: A modern interior design project executed with precision and expertise.",
    filter: "Design and Execution",
    type: "Interior design project",
    year: 2020,
  },
  {
    Id: 40,
    image: [
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/1.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/2.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/3.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/4.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/5.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/6.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/7.jpg"),
      require("./../../images/projects/latest/Front-Villa-Ahmad-Achour-in-Syria/8.jpg"),
    ],
    title: "Villa Syria Mr. Ahmad Ashour",
    description:
      "Villa Syria Mr. Ahmad Ashour, Jdaydet Yebouz, Syria: A 2000 m² duplex project with architecture and interior design, expertly executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior project",
    year: 2021,
  },
  {
    Id: 41,
    image: [
      require("./../../images/projects/latest/Front-Gandini-Menswear/1.jpg"),
      require("./../../images/projects/latest/Front-Gandini-Menswear/2.jpg"),
      require("./../../images/projects/latest/Front-Gandini-Menswear/3.jpg"),
      require("./../../images/projects/latest/Front-Gandini-Menswear/4.jpg"),
      require("./../../images/projects/latest/Front-Gandini-Menswear/5.jpg"),
    ],
    title: "Gandini men’s wear",
    description:
      "Gandini Men's Wear, Hamra, Beirut, Lebanon: A 200 m² modern interior and exterior façade design project, executed with precision and expertise.",
    filter: "Design and Execution",
    type: "Interior and Exterior Façade Design",
    year: 2017,
  },
  {
    Id: 42,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Nissane/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Nissane/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Nissane/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Nissane/4.jpg"),
    ],
    title: "Nissan Cars Showroom",
    description:
      "Nissan Cars Showroom in Makka, Saudi Arabia: An exterior façade design project with a focus on design and executional drawings.",
    filter: "Design and executional drawings",
    type: "Exterior Façade Design",
    year: 2014,
  },

  {
    Id: 43,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Ankoun/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Ankoun/2.jpg"),
    ],
    title: "Private Villa in Ankoun",
    description:
      "Private Villa, Ankoun, Lebanon: A 700 m² modern project with architecture and execution drawings, expertly executed.",
    filter: "Design and execution drawing",
    type: "Architecture project",
    year: 2021,
  },
  {
    Id: 44,
    image: [
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/1.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/2.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/3.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/4.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/5.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/6.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/7.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/8.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/9.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/10.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/11.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/12.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/13.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/14.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/15.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/16.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/17.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/18.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/19.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/20.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/21.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/22.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/23.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/24.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/25.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/26.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/27.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/28.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/29.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/30.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/31.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/32.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/33.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/34.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/35.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/36.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/37.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/38.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/39.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/40.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/41.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/42.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/43.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Abu-Ali-Haris/44.jpg"),
    ],
    title: "Villa Jawad, Haris",
    description: "",
    filter: "Design and execution drawing",
    type: "Architecture project",
    year: 2024,
  },
  {
    Id: 45,
    image: [
      require("./../../images/projects/Website-Photos-New/New-to-add/Chalet-Hamra/1.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Chalet-Hamra/2.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Chalet-Hamra/3.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Chalet-Hamra/4.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Chalet-Hamra/5.jpg"),
      require("./../../images/projects/Website-Photos-New/New-to-add/Chalet-Hamra/6.jpg"),
    ],
    title: "Chalet Hamra",
    description: "",
    filter: "Design and execution drawing",
    type: "Architecture project",
    year: 2024,
  },
  {
    Id: 46,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/9.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/10.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/11.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/12.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/13.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Damour-Spa/14.jpg"),
    ],
    title: "Blue Sky Damour Exterior",
    description:
      "Blue Sky Damour, Lebanon: A 6000m² modern project with chalets and a restaurant, expertly designed and executed.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2023,
  },
  {
    Id: 47,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/8.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/9.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/10.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/11.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/12.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Bustan-al-sukkar/13.jpg"),
    ],
    title: "Bustan Al Sukkar Exterior",
    description:
      "Situated in Ainab, Mount Lebanon, this modern architecture and interior landscape project offers elegantly designed commercial villas for rent, blending luxurious aesthetics with functional design from concept to execution.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2024,
  },
  {
    Id: 48,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Bustan-al-sukkar/7.jpg"),
    ],
    title: "Bustan Al Sukkar Interior",
    description:
      "Situated in Ainab, Mount Lebanon, this modern architecture and interior landscape project offers elegantly designed commercial villas for rent, blending luxurious aesthetics with functional design from concept to execution.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2024,
  },
  {
    Id: 49,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Hamra/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Hamra/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Hamra/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Hamra/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Hamra/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Hamra/6.jpg"),
    ],
    title: "Lotus Villas",
    description:
      "Located in Al-Hamra, Nabatieh, these 2000 m² modern commercial villas blend luxury and functionality, offering premium rental spaces designed and executed to perfection.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2024,
  },
  {
    Id: 50,
    image: [
      require("./../../images/projects/Website-Photos-New/Interior/Joun/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/7.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/8.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/9.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/10.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/11.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/12.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/13.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/14.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/15.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/16.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/17.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/18.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/19.jpg"),
      require("./../../images/projects/Website-Photos-New/Interior/Joun/20.jpg"),
    ],
    title: "Villa Mr.Hassan Al Enjibar Interior",
    description:
      "Located in Joun, South Lebanon, this modern residential project seamlessly integrates architecture, interior, and landscape design for a cohesive and contemporary living experience.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2024,
  },
  {
    Id: 51,
    image: [
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/1.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/2.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/3.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/4.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/5.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/6.jpg"),
      require("./../../images/projects/Website-Photos-New/Exterior/Joun/7.jpg")
    ],
    title: "Villa Mr.Hassan Al Enjibar Exterior",
    description:
      "Located in Joun, South Lebanon, this 1000 m² modern residential project seamlessly integrates architecture, interior, and landscape design for a cohesive and contemporary living experience.",
    filter: "Design and Execution",
    type: "Architecture and Interior and Landscape project",
    year: 2024,
  },
];

const imgStyle = {
  width: "100%", // Set the image width to 100% of the parent div
  height: "100%", // Set the image height to 100% of the parent div
  objectFit: "cover", // Maintain aspect ratio and cover the div
};

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.carouselRef = React.createRef();
    this.state = {
      imageHeight: 0, // Initialize with a default value
    };
  }

  componentDidMount() {
    const projectId = parseInt(this.props.match.params.id); // Assuming 'id' is the parameter name in the URL
    const projectExists = projects.some((p) => p.Id === projectId);

    if (!projectExists) {
      window.location.href = "/";
    }
    this.imageRef.current.onload = this.updateImageHeight;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const middlePosition = windowHeight / 3;
    window.scrollTo(0, middlePosition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.updateImageHeight();
    }
  }

  updateImageHeight = () => {
    const img = this.imageRef.current;
    if (img) {
      this.setState(
        {
          imageHeight: img.clientHeight,
        },
        () => {
          this.positionNavButtons(); // Call positionNavButtons after image height is updated
        }
      );
    }
  };

  handleCarouselInit = (event) => {
    this.positionNavButtons();
  };

  handleCarouselResize = (event) => {
    this.positionNavButtons();
  };

  positionNavButtons = () => {
    const carousel = this.carouselRef.current;
    const imageHeight = this.state.imageHeight || 0;
    const navButtons = document.querySelectorAll(".owl-prev, .owl-next");
    console.log(imageHeight);
    navButtons.forEach((button) => {
      button.style.top = `${imageHeight / 2}px`;
    });
  };

  render() {
    const { id } = this.props.match.params;
    const options = {
      loop: true,
      margin: 0,
      autoplay: false,
      autoplayTimeout: 3000,
      nav: true,
      dots: false,
      onInitialized: this.handleCarouselInit,
      onResized: this.handleCarouselResize,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 1,
        },
        767: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };
    return (
      <>
        <Header2 />
        <div className="page-content">
          <Banner
            title="Creating places that enhance the human experience."
            pagename="Project detail"
            bgimage={bnrimg.default}
          />
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb90 tm-work-detail-wrap">
            <div className="section-full p-t90 p-lr80 latest_project-outer square_shape3">
              <div
                className="project-detail-outer bg-top-left bg-parallax bg-center"
                data-stellar-background-ratio="0.5"
                //  style={{ backgroundImage: 'url(' + bgimg.default + ')' }}
              >
                {projects
                  .filter((p) => p.Id === parseInt(id))
                  .map((project) => {
                    return (
                      <div className="row">
                        <div className="col-md-9 col-sm-12 project-detail-containt  square_shape3">
                          <div>
                            <OwlCarousel
                              className="owl-carousel home-carousel-1 owl-btn-vertical-center"
                              {...options}
                            >
                              {project.image.map((img) => (
                                <div className="item">
                                  <div className="ow-img wt-img-effect zoom-slow">
                                    <img
                                      ref={this.imageRef}
                                      src={img.default}
                                      alt="Your Image"
                                      style={imgStyle}
                                    />
                                  </div>
                                </div>
                              ))}
                            </OwlCarousel>
                          </div>
                        </div>
                        <div
                          className="col-md-3 col-sm-12 project-detail-containt bg-black square_shape3"
                          style={{
                            minHeight: "495px",
                          }}
                        >
                          <div className=" padding-top80">
                            <div
                              className="bg-white p-lr30 p-tb50 text-black"
                              style={{
                                minHeight: "445px",
                              }}
                            >
                              <h4 className="m-t0">
                                <span className="font-26 text-uppercase">
                                  {project.title}
                                </span>
                              </h4>
                              <p>{project.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
