import React, { useEffect, useState } from 'react';

const Statistics = () => {
    let bgimg = require('./../../images/background/bg-11.jpg');
    const [count, setCount] = useState(0);
    const [endValue, setEndValue] = useState(532);
  
    const [count2, setCount2] = useState(0);
    const [endValue2, setEndValue2] = useState(new Date().getFullYear() - 2012);
  
    const duration = 5000; // 5000 milliseconds (5 seconds)
    const intervalIds = []; // Keep track of interval IDs
  
    useEffect(() => {
      const start = Date.now();
  
      const updateCount = (startValue, endValue, setFunc) => {
        const interval = 100; // Increase the interval for slower animation
        const steps = duration / interval;
        const stepValue = (endValue - startValue) / steps;
  
        let currentCount = startValue;
  
        const intervalId = setInterval(() => {
          currentCount += stepValue;
          setFunc(Math.floor(currentCount));
  
          if (currentCount >= endValue) {
            setFunc(endValue);
            clearInterval(intervalId);
          }
        }, interval);
  
        // Keep track of interval IDs
        intervalIds.push(intervalId);
      };
  
      updateCount(0, endValue, setCount);
      updateCount(0, endValue2, setCount2);
  
      return () => {
        // Clear all existing intervals
        intervalIds.forEach(clearInterval);
      };
    }, [endValue, endValue2]);
        return (
            <>
                <div className="section-full p-t90 m-tb100 p-b50 overlay-wraper bg-top-center bg-parallax tm-facts-wrap" data-stellar-background-ratio="0.5" style={{ backgroundImage: "url(" + bgimg.default + ")" }}>
                        <div className="overlay-main opacity-08 bg-black" />
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="some-facts">
                                        <div className="text-white">
                                            {/* <span className="font-40 font-weight-300  text-uppercase">Some</span> */}
                                            <h2 className="font-40  text-uppercase">
                                            Our Accomplishments
                                            </h2>
                                            <p className="font-18 font-weight-300">
                                                {/* Morbi mattis ex non urna condimentum, eget eleif
                                                end diam molestie. Curabitur lorem enim, maximus
                                                non nulla sed, egestas venenatis felis. */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row some-facts-counter">
                                    <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                    <div className="font-40 font-weight-600 m-b5"><span className="counter">{count}</span></div>
                                                    <div className="wt-separator-outer m-b20"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Completed  projects</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                    <div className="font-40 font-weight-600 m-b5"><span className="counter">{count}</span></div>
                                                    <div className="wt-separator-outer m-b20"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Happy Clients</span>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                    <div className="font-40 font-weight-600 m-b5"><span className="counter">{count2}</span></div>
                                                    <div className="wt-separator-outer m-b20"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Years of Experience</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    
};

export default Statistics;